import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import css from './TextAnimate.module.css';

const TextAnimate = ({ toRotate, period, onTextChange }) => {
    const [text, setText] = useState('');  // Current text being displayed
    const [isDeleting, setIsDeleting] = useState(false);  // Whether the component is deleting characters
    const [loopNum, setLoopNum] = useState(0);  // Track the word index in the rotation
    const [delta, setDelta] = useState(300);  // Speed of typing and deleting
    const [isPause, setIsPause] = useState(false); // Control pause between word cycles
    const [pauseTimeout, setPauseTimeout] = useState(null); // Control timeout between words

    useEffect(() => {
        // Main typing logic
        const handleType = () => {
            const i = loopNum % toRotate.length;  // Index of current word
            const fullTxt = toRotate[i];  // Current word to type

            // Adjust text based on typing or deleting mode
            setText((prevText) =>
                isDeleting
                    ? fullTxt.substring(0, prevText.length - 1)
                    : fullTxt.substring(0, prevText.length + 1)
            );

            // If the full word is typed
            if (!isDeleting && text === fullTxt) {
                // Pause before starting deletion
                setIsPause(true);
                setPauseTimeout(setTimeout(() => {
                    setIsPause(false);
                    setIsDeleting(true);
                }, period)); // Wait for the provided period before deleting
            }

            // If the word is fully deleted
            else if (isDeleting && text === '') {
                setIsDeleting(false);  // Start typing the next word
                setLoopNum(loopNum + 1);  // Move to the next word in the loop
            }

            // Adjust typing speed
            setDelta(isDeleting ? 50 : 100);  // Delete faster than typing
        };

        // Start typing or deleting only when not paused
        if (!isPause) {
            const ticker = setTimeout(() => handleType(), delta);
            return () => clearTimeout(ticker);  // Cleanup the timeout
        }

        return () => clearTimeout(pauseTimeout); // Clear pause timeout when component unmounts
    }, [text, isDeleting, loopNum, delta, isPause, toRotate, period]);  // Effect dependencies

    useEffect(() => {
        const currentWord = toRotate[loopNum % toRotate.length];
        // Notify the parent when switching to a new word
        if (!isDeleting && text === '' && currentWord !== text) {
            onTextChange(currentWord); // Update with the current word
        }
    }, [text, loopNum, isDeleting, onTextChange, toRotate]);

    return (
        <span className={css.animationText}>{text}</span>
    );
};

TextAnimate.propTypes = {
    toRotate: PropTypes.arrayOf(PropTypes.string).isRequired,  // List of words to rotate
    period: PropTypes.number,  // Delay before deleting a word
};

TextAnimate.defaultProps = {
    period: 2000,  // Default pause between word cycles (2 seconds)
};

export default TextAnimate;